import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const CreditAndRiskManagement = () => {
    return (
        <Layout>
            <Seo title="Credit and Risk Management" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Credit and Risk Management</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-md-6 order-2 ">
                                <div className="card-body services-text d-flex flex-column">
                                    <p className="card-text">
                                        Delta is pleased to offer a full suite of credit facilities to our customers. We have
                                        industry-leading credit management who truly understand shipping and can tailor a credit
                                        offering that maximizes flexibility, allows you the freedom, and support you need in these
                                        uncertain times. In these turbulent times, we understand perfectly that our customers may want
                                        to have additional reassurance for price and supply risks for their business.
                                    </p>
                                    <p>
                                        Delta has a highly-experienced Risk team that can offer you solutions that are tailored to your
                                        business. With this in place and a risk management team with accessible hedging tools, Delta is
                                        able to offer various pricing structures and contracts such as fixed forward prices, floating
                                        price contracts, spot pricing and are able to advise on derivatives and use such tools to give
                                        the flexibility to our clients when they want to hit the fix button during extremely volatile
                                        price movements.
                                    </p>
                                    <p>
                                        We are eager to expand and diversify which will entail offering the best credit and risk
                                        solutions to our clients. All of our suppliers are vetted and when compliance has never been
                                        more important we maintain robust and comprehensive compliance procedures and KYC’s on all of
                                        our service providers. Avoid surprises and feel safe working together with the Delta Group.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 order-1 order-md-2 d-flex justify-content-center ">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/service/creditandrisk1.jpg"
                                    aspectRatio={16 / 9}
                                    width={640}
                                    layout="fixed"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CreditAndRiskManagement
